import { createStore } from 'vuex'
import { getJSONUrl } from "../utils/Urls";
// import data from '../../public/appstore-data/apps.json';
import { cloneDeep } from 'lodash';

const filterApps = (state) => {
	let apps = state.apps;
	// FIXME: i18n
	state.filters.forEach((value, key, map) => {
		if (value != "") {
			if (key == "search" && value) {
				apps = apps.filter((app) => app.localized_data['en'].searchtext.indexOf(value.toLowerCase()) !== -1);
			} else if (key == "usecases") {
				apps = apps.filter((app) => app.localized_data['en']['use_cases'] && value.every((v) => app.localized_data['en']['use_cases'].includes(v)));
			} else if (key == "stores") {
				apps = apps.filter((app) => app.app_store && value.every((v) => app.app_store.title == v));
			}
		}
	});
	return apps;
};

export default createStore({
	state: {
		apps: [],
		filteredApps: [],
		filters: new Map(),
		usecases: {},
		stores: {},
	},
	mutations: {
		add(state, app) {
			state.apps.push(app);
		},
		shuffle(state) {
			state.filteredApps.sort(() => .5 - Math.random());
		},
		applyFilterSort(state) {
			const tmp = state.apps.sort((a, b) => {
				const indexA = this.getters.filteredAppsIds.indexOf(a.app_id);
				const indexB = this.getters.filteredAppsIds.indexOf(b.app_id);
				if (indexA == indexB) { return 0 }
				if (indexA == -1) return -1;
				if (indexB == -1) return 1;
				return indexA > indexB ? -1 : 1;
			});
		},
		applyFilters(state) {
			const apps = filterApps(state);
			if (JSON.stringify(apps) !== JSON.stringify(state.filteredApps)) {
				state.filteredApps = [...apps];
			}
		},
		setFilter(state, options) {
			if (options.filter == 'search') {
				state.filters.set(options.filter, options.value);
			} else {
				let value = state.filters.get(options.filter) || [];
				if (value.includes(options.value)) {
					value = value.filter((v) => v != options.value);
				} else {
					if (options.filter == 'stores') {
						value = [options.value];
					} else {
						value.push(options.value);
					}
				}
				state.filters.set(options.filter, value);
			}
		},
		clearFilters(state, filter) {
			if (filter) {
				state.filters.set(filter, []);
			} else {
				state.filters.clear();
			}
		},
		backupFilters(state) {
			this.filterBackup = cloneDeep(state.filters);
		},
		applyFiltersBackup(state) {
			state.filters = cloneDeep(this.filterBackup);
		}
	},
	actions: {
		async populate(context) {

			// const response = await fetch('res://appstore-data/apps.json');
			const response = await fetch(getJSONUrl());
			const data = await response.json();
		
			if (data && data.apps) {

				// remove apps without data (testapp)
				data.apps = data.apps.filter((app) => { return app.data && app.data.length > 0; });

				// sort randomly
				data.apps.sort(() => .5 - Math.random());

				// FIXME: i18n
				context.state.usecases['de'] = [];
				context.state.usecases['en'] = [];
				context.state.stores = [
					// 'Mindsphere', 'Industrial Edge Marketplace', 'PLM Store'
				];

				data.apps.forEach((app) => {
					app.localized_data = {};
					if (app.app_store && app.app_store.title) {
						const storeTitle = app.app_store.title.replace('Industrial Edge Marketplace', 'Industrial Edge');
						app.app_store.title = storeTitle;
						if (!context.state.stores.includes(app.app_store.title)) {
							context.state.stores.push(storeTitle);
						}
					}
					app.data.forEach((data) => {
						data.use_cases.forEach((usecase) => {
							if (!context.state.usecases[data.language].includes(usecase)) {
								context.state.usecases[data.language].push(usecase);
							}
						});
						app.localized_data[data.language] = data;
						app.localized_data[data.language]['searchtext'] = `
							${app.app_title}
							${data.title}
							${data.heading}
							${data.description}
							${data.features}
							${data.advantages}
						`.toLowerCase();
					});
					context.commit('add', app);
				});
				context.state.filteredApps = [...context.state.apps];

				// debug: pre-filter
				// this.state.filters.set("search", "stream");
				// this.state.filters.set("usecases", ["quality", 'test', 'test2']);
				// this.state.filters.set("stores", ['Mindsphere']);
				// this.commit("applyFilters");
			}
		}
	},
	modules: {
	},
	getters: {
		filteredAppsCount(state) {
			return filterApps(state).length;
		},
		filteredApps: (state) => {
			return state.filteredApps;
		},
		filteredAppsIds: (state) => {
			return state.filteredApps.map((app) => app.app_id);
		},
		appById: state => appId => {
			return state.apps.filter(x => x.app_id == appId)
		},
		filters: (state) => {
			return state.filters;
		}
	},
})
