<template>
	<div class="c-home c-page">
		<div class="c-home__overlay" :class="{'c-home__overlay--show': showFilter}"></div>
		<div class="c-home__welcome">
			<header>
				<h1 v-html="welcomeHeadline"></h1>
				<h2 v-html="welcomeText"></h2>
			</header>
		</div>
		<Transition name="enter">
			<div v-show="idle" class="c-idlecontainer">
				<NewsBanner :message="bannerMessage"  />
				<EnterButton
					@entered="entered = true"
				/>
			</div>
		</Transition>
		<Transition name="filter">
			<AppFilter
				@uiLock="uiLock"
				@toggleFilter="toggleFilter"
				:uiLocked="uiLocked"
				:idle="idle"
				v-show="!idle"
				/>
		</Transition>
		<AppList
			@uiLock="uiLock"
			:showFilter="showFilter"
			:uiLocked="uiLocked"
			:idle="idle"
			/>
		<NewsTicker :header="tickerHeader" :messages="tickerMessages" :speed="tickerSpeed" />
	</div>
</template>

<script>
import EnterButton from '@/components/EnterButton.vue'
import AppFilter from '@/components/AppFilter.vue'
import AppList from '@/components/AppList.vue'
import NewsBanner from '@/components/NewsBanner.vue'
import NewsTicker from '@/components/NewsTicker.vue'

export default {
	name: 'Home',
	props: [
		'idle',
	],
	data() {
		return {
			showFilter: false,
			entered: false,
			uiLocked: false,
		}
	},
	computed: {
		bannerMessage() { return window.config.get('bannerMessage'); },
		tickerHeader() { return window.config.get('tickerHeader'); },
		tickerMessages() { return window.config.get('tickerMessages'); },
		tickerSpeed() { return window.config.get('tickerSpeed'); },
		welcomeHeadline() { return window.config.get('welcomeHeadline'); },
		welcomeText() { return window.config.get('welcomeText'); },
		welcomeTextFontSize() { return window.config.get('welcomeTextFontSize'); },
	},
	watch: {
		idle() {
			if (this.idle) {
				this.entered = false;
				this.showFilter = false;
			}
		}
	},
	methods: {
		toggleFilter() {
			if (this.$data.showFilter) {
				this.$data.showFilter = false;
			} else {
				this.$data.showFilter = true;
			}
		},
		uiLock(state) {
			this.$data.uiLocked = state;
		}
	},
	components: {
    AppFilter,
    AppList,
    EnterButton,
    NewsBanner,
    NewsTicker
},
}
</script>
<style lang="scss" scoped>
.c-home {
	height: 100%;
	overflow: hidden;

	&__welcome {
		display: inline-block;
		position: relative;
		z-index: 10;
		width: 100%;

		header {
			@include rule-headlines {
				&:nth-child(2) {
					font-size: calc(v-bind('welcomeTextFontSize') * 0.0925928205128205vw);
				}
			}
		}
	}

	&__overlay {
		top: 0;
		left: -100vw;
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(90deg, rgba(0, 0, 40, 1) 0%, rgba(0, 0, 40, .5) 50%, rgba(0, 0, 40, 0) 100%);
		z-index: 10;
		transition: left 0.3s ease-in-out;
		&--show {
			left: 0;
		}
	}

	.c-idlecontainer {
		position: absolute;
		width: calc(100% - (vw(53) * 2));
		z-index: 10;
	}

	.enter-enter-active,
	.enter-leave-active {
		transition: opacity 0.5s ease;
	}

	.enter-enter-from,
	.enter-leave-to {
		opacity: 0;
	}

	.filter-enter-active,
	.filter-leave-active {
		transition: opacity 0.5s ease 0.3s;
	}

	.filter-enter-from,
	.filter-leave-to {
		opacity: 0;
	}
}
</style>