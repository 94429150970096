function isElectron() {
	// Renderer process
	if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
			return true;
	}

	// Main process
	if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
			return true;
	}

	// Detect the user agent when the `nodeIntegration` option is set to true
	if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
			return true;
	}

	return false;
}

function getAssetsUrl() {
	if (process.env.NODE_ENV === 'development') {
		return 'http://siemens-appstore-client.rincewind.esirion.de/appstore-data/';
	} else if (isElectron()) {
		return 'res://appstore-data/';
	}
	return './appstore-data/'
}
function getJSONUrl() {
	return isElectron() ? 'res://appstore-data/apps.json' : './appstore-data/apps.json';
}
export {
	getAssetsUrl, getJSONUrl, isElectron
}