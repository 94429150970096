<template>
	<div class="c-newsbanner" v-if="message && message.length > 0">
			<img src="@/assets/free-icon.svg">
			<div class="c-newsbanner__message">
					<h3>NEW</h3>
					<p v-html="message"></p>
			</div>
	</div>
</template>

<script>
import { toRaw } from 'vue';

export default {
	name: 'newsbanner',
	props: {
		"message": { type: String },
	},
	data() {
		return {
		}
	},
	methods: {
		onSwiper(swiper) {
			this.swiper = swiper;
		}
	},
	components: {
	},
}
</script>

<style lang="scss">
.c-newsbanner {
	$c: &;
	background: white;
	color: #000028;
	padding: vw(20);
	display: flex;
	gap: vw(27);
	margin-bottom: vw(43);
	width: 100%;

	img {
		width: vw(88);
	}

	h3 {
		color: #007993;
		// margin-bottom: vw(6);
		margin-bottom: 0;
		font-size: vw(18);
		letter-spacing: vw(-0.54);
		font-weight: 600;
	}
	p {
		margin: 0 0 vw(6);
		letter-spacing: vw(-0.44);
		line-height: vw(28);
		font-size: vw(22);
		font-weight: 600;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>