<template>
	<div class="c-newsticker" v-if="header && messages && messages.length > 0">
			<header>
				<h2>Partner Program</h2>
				{{header}}
			</header>
			<div class="c-newsticker__ticker">
					<!-- <vue3-marquee clone="true">{{message}}</vue3-marquee> -->
					<swiper
						direction="vertical"
						:speed="1000"
						:loop="true"
						:slidesPerView="1"
						:centeredSlides="true"
						:autoplay='{
							"delay": speed,
							"disableOnInteraction": false
						}'
					>
						<swiper-slide v-for="message in messages" :key="message">
							<div class="slide-content">{{message}}</div>
						</swiper-slide>
					</swiper>

			</div>
	</div>
</template>

<script>
// import { Vue3Marquee } from 'vue3-marquee'
// import 'vue3-marquee/dist/style.css'
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import { toRaw } from 'vue';

SwiperCore.use([Autoplay]);

export default {
	name: 'NewsTicker',
	props: {
		"header": { type: String },
		"messages": { type: Array },
		"speed": { type: Number, default: 5000 }
	},
	data() {
		return {
			swiper: null,
			hideSwiper: false,
		}
	},
	methods: {
		onSwiper(swiper) {
			this.swiper = swiper;
		}
	},
	components: {
		// Vue3Marquee,
		Swiper,
		SwiperSlide
	},
	watch: {
		'$route'(to, from) {
			// fixme: swiper autoplay stops on route change
			// this.$nextTick(() => {
			// 	this.hideSwiper = true;
			// 	this.$nextTick(() => {
			// 		this.hideSwiper = false;
			// 	});
			// });
			setTimeout(() => {
				this.hideSwiper = true;
				setTimeout(() => {
					this.hideSwiper = false;
				}, 200);
			}, 100);
		}
	},
}
</script>

<style lang="scss">
.c-newsticker {
	$c: &;
	$height: vw(114);

	position: absolute;
	z-index: 110;
	width: 100%;
	height: $height;
	overflow: hidden;
	left: 0;
	bottom: 0;
	// top: 0;
	background-color: white;
	// border-radius: vw(4);
	overflow: hidden;

	display: flex;

	header {
		color: #000028;
		background-color: white;
		// background: linear-gradient(90deg,#00d7a0 0,#00bedc 100%);
		// flex: 3;
		width: vw(450);
		max-width: 100%;
		padding: 0;
		margin: 0;
		font-size: vw(18);
		padding: vw(12) 0 vw(12) vw(40);
		font-weight: bold;
		display: flex;
		flex-direction: column;
		justify-content: center;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: vw(37);
			line-height: vw(37);
			text-transform: none;
			letter-spacing: vw(-3);
			color: #00b3b3;
			margin-bottom: 0;
		}
		&:before {
			display: none;
		}
	}

	&__ticker {
		flex: 5;
		color: #000028;
		// padding: vw(12) vw(40);
		font-size: vw(24);
		letter-spacing: vw(-1);
		* {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.swiper-container {
		height: 100%;
	}

	.swiper-slide {
		.slide-content {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			height: $height !important;
			font-size: vw(22);
			font-weight: bold;
			text-align: left;
			padding-right: vw(40);
		}
	}

	.vue3-marquee {
		.marquee {
			margin-right: vw(96);
		}
	}
}
</style>