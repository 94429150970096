<template>
	<div class="c-filter">
		<div :class="{ 'c-filter__page': true, '--hide': showTags || idle }" >
			<button
				v-on:click="togglePage"
				class="o-button--filter"
				>
					Filter by Use Cases
				</button>
			<div class="c-filter__searchfilter">
				<input
					v-model="inputSearchField"
					v-on:keyup.enter="applySearch"
					v-on:focus="searchfieldFocus"
					placeholder="Search for..."
					>
				<button
					class="c-filter__searchfilter_clear"
					v-show="inputSearchField"
					v-on:click="clearSearch"
					></button>
				<button
					v-on:click="applySearch"
					class="o-button--filter o-button--icon-magnifier"
					>
				</button>
			</div>

			<transition-group name="list" tag="ul" class="c-filter__activefilters">
				<li
					class="c-filter__toggle c-filter__toggle--deletable"
					v-for="store in filters.get('stores') || []"
					:key="store"
					@click="toggleFilter('stores', store, true)"
				>
					{{ store }}
				</li>
				<li
					class="c-filter__toggle c-filter__toggle--deletable"
					v-for="usecase in filters.get('usecases') || []"
					:key="usecase"
					@click="toggleFilter('usecases', usecase, true)"
				>
					{{ usecase }}
				</li>
				<!-- <li
					class="c-filter__toggle c-filter__toggle--search c-filter__toggle--deletable"
					v-if="filters.get('search') && !showKeyboard"
					@click="clearSearch"
				>
					{{filters.get('search')}}
				</li> -->
				<li
					class="c-filter__toggle"
					v-if="filters.get('stores') && filters.get('stores').length > 0 || (filters.get('usecases')) && filters.get('usecases').length > 0"
					@click="clearFilters()"
				>
					Reset all
				</li>
			</transition-group>
		</div>

		<div :class="{'--show': showTags, 'c-filter__page': true, 'c-filter__tags': true }">
			<!-- <p>Filter apps by <strong>Store</strong></p>
			<ul class="c-filter__stores">
				<li
					:class="{
						'c-filter__toggle': true,
						'c-filter__toggle--selected': filters.get('stores') && filters.get('stores').includes(store)
					}"
					v-for="store in stores"
					:key="store"
					v-on:click="toggleFilter('stores', store)"
				>
					{{ store }}
				</li>
			</ul>
			<br> -->
			<p>Filter Industrial Edge Apps by Use Cases</p>
			<ul class="c-filter__usecases">
				<li
					:class="{
						'c-filter__toggle': true,
						'c-filter__toggle--selected': filters.get('usecases') && filters.get('usecases').includes(usecase)
					}"
					v-for="usecase in usecases"
					:key="usecase"
					v-on:click="toggleFilter('usecases', usecase)"
				>
					{{ usecase }}
				</li>
			</ul>
			<br><br>
			<button class="o-button--cancel" v-on:click="hidePage">Cancel</button>
			<button class="o-button" v-on:click="applyFilter">Show {{appcount}} results</button>
		</div>
		<div class="simple-keyboard hg-theme-default hg-layout-default" :class="{ 'simple-keyboard--show': showKeyboard}"></div>
	</div>
</template>

<script>
import store from "../store";
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';

export default {
	name: 'AppFilter',
	props: [
		'uiLocked',
		'idle'
	],
	data() {
		return {
			showTags: false,
			inputSearchField: "",
			inputSearchFieldBeforeKeyboard: "",
			keyboard: null,
			showKeyboard: false,

		}
	},
	computed: {
		usecases() {
			return store.state.usecases[this.$i18n.locale];
		},
		stores() {
			return store.state.stores;
		},
		filters() {
			return store.state.filters;
		},
		appcount() {
			return store.getters.filteredAppsCount;
		}
	},
	watch: {
		appcount() {
			const enter = document.querySelector('.hg-button-enter');
			enter.textContent = `Show ${this.appcount}`;
		},
		inputSearchField() {
			store.commit('setFilter', {filter: 'search', value: this.inputSearchField });
		},
		idle() {
			if (this.idle) {
				this.showTags = false;
				this.showKeyboard = false;
				this.$emit('uiLock', false);
			}
		}
	},
	emits: [
		'toggleFilter',
		'uiLock',
	],
	methods: {
		togglePage() {
			if (this.uiLocked && !this.showKeyboard) {
				return false;
			}
			this.$emit('uiLock', false);
			if (this.$data.showTags) {
				this.$data.showTags = false;
			} else {
				this.$data.showTags = true;
				store.commit('backupFilters');
			}
			if (this.showKeyboard) {
				this.keyboard.setInput(this.inputSearchFieldBeforeKeyboard);
				this.showKeyboard = false;
			}
			this.$emit('toggleFilter');
		},
		hidePage() {
			const ul = document.querySelector('.c-filter__activefilters');
			ul.classList.add('list-leave-notransition');
			store.commit('applyFiltersBackup');
			this.togglePage();
			setTimeout(() => {
				ul.classList.remove('list-leave-notransition');
			}, 300);

		},
		toggleFilter(filter, value, apply) {
			if (this.uiLocked) {
				return false;
			}
			const ul = document.querySelector('.c-filter__activefilters');
			let count = 0;
			store.getters.filters.forEach((value, filter) => {
				if (value) {
					count += value.length;
				}
			});
			if (store.getters.filters.get(filter) && count == 1) {
				ul.classList.add('list-leave-all');
			}
			store.commit("setFilter", { filter: filter, value: value });
			if (apply) {
				store.commit('applyFilters');
			}
			setTimeout(() => ul.classList.remove('list-leave-all'), 300)
		},
		clearFilters() {
			if (this.uiLocked) {
				return false;
			}
			const ul = document.querySelector('.c-filter__activefilters');
			ul.classList.add('list-leave-all');
			store.commit('clearFilters', 'stores');
			store.commit('clearFilters', 'usecases');
			store.commit('applyFilters');
			setTimeout(() => ul.classList.remove('list-leave-all'), 300)
		},
		applyFilter() {
			if (this.uiLocked) {
				return false;
			}
			this.togglePage();
			setTimeout(() => {
				store.commit('applyFilters');
			}, 400);
		},
		clearSearch() {
			if (this.uiLocked && !this.showKeyboard) {
				return false;
			}
			this.inputSearchField = "";
			this.keyboard.setInput("");
			this.applySearch();
		},
		applySearch() {
			if (this.uiLocked && !this.showKeyboard) {
				return false;
			}
			this.$emit('uiLock', false);
			this.$nextTick(() => {
				store.commit('setFilter', {filter: 'search', value: this.inputSearchField });
				this.showKeyboard = false;
				store.commit('applyFilters');
			})
		},
		searchfieldFocus() {
			// if (!this.showKeyboard) {
			// 	this.keyboard.clearInput();
			// }
			this.showKeyboard = true;
			this.inputSearchFieldBeforeKeyboard = this.inputSearchField;
			this.$emit('uiLock', true);
		},
	},
	mounted() {
		store.subscribe((mutation, state) => {
			if (mutation.type == 'clearFilters' && state.filters.get('search') != this.inputSearchField) {
				this.inputSearchField = state.filters.get('search');
			}
			// if (mutation.type == 'setFilter') {
			// 	this.inputSearchField = store.state.filters.search || "";
			// }
		});
		this.keyboard = new Keyboard({
			onChange: input => { this.inputSearchField = input; },
			onKeyPress: button => {
				if (button === '{enter}')	{
					this.applySearch();
				} else if (button === '{close}')	{
					this.keyboard.setInput(this.inputSearchFieldBeforeKeyboard);
					this.showKeyboard = false;
					this.$emit('uiLock', false);
				}
			},
			//...layout,
			layout: {
				'default': [
					'Q W E R T Y U I O P',
					'A S D F G H J K L',
					'Z X C V B N M {bksp}',
					'{close} {space} {enter}'
				],
			},
			display: {
				'{close}': 'Cancel',
				'{space}': '&mdash;',
				'{bksp}': ' ',
				'{enter}': `Show ${this.appcount}`,
			}
		});
	}
}
</script>

<style lang="scss" scoped>
.c-filter {

	$c: &;

	position: relative;
	z-index: 10;
	opacity: 1;

	&__page {
		position: absolute;
		transition: opacity 0.3s ease-in-out;
		font-size: vw(24);

		&.--hide {
			opacity: 0;
		}

		&#{$c}__tags {
			transform: translateX(-100vw);
			transition: transform 0.5s ease-in-out;

			&.--show {
				transform: translateX(0);
			}
		}

		ul {
			padding:0;
			margin:0;
			list-style-type: none;
			max-width: 70vw;
		}

		#{$c}__searchfilter {

			display: inline-block;

			input {
				border:0;
				font-size: vw(20);
				padding: vw(16);
				outline: 0;
				margin-left: vw(18);
				height: vw(64);
				line-height: 100%;
				min-width: vw(415);
				font-weight: 600;
				color: #4C4C68;

				&::placeholder {
					color: inherit;
					opacity: 1;
				}
			}

			.o-button {
				min-width: 0;
			}

			&_clear {
				position: absolute;
				margin-left: vw(-64);
				width: vw(64);
				aspect-ratio: 1;
				background: transparent url("~@/assets/icons/x.svg") center center no-repeat;
				background-size: vw(26) auto;
				border: 0;
			}
		}

		#{$c}__toggle {
			display: inline-block;
			border:1px solid $color-filter-toggle;
			border-radius: vw(20);
			background-color: $color-filter-toggle-bg;
			color: $color-filter-toggle;
			font-size: vw(14);
			line-height: vw(36);
			padding: 0 vw(26) 0;
			margin: 0 vw(10) vw(10) 0;

			transition: background-color 0.1s linear, color 0.1s linear, padding 0.05s linear;

			&--selected {
				background-color: $color-filter-toggle-selected-bg;
				color: $color-filter-toggle-selected;

				padding-right: vw(16);
				padding-left: vw(36);
				background-image: url("~@/assets/icons/check-green.svg");
				background-repeat: no-repeat;
				background-position: left vw(16) center;
				background-size: vw(11) auto;
			}

			&--deletable {

				padding-right: vw(48);
				background-image: url("~@/assets/icons/x-green.svg");
				background-repeat: no-repeat;
				background-position: right vw(16) center;
				background-size: vw(11) auto;
			}

			&--search {
				&:before {
					content: '';
					position: relative;
					padding-left: vw(16);
					left: vw(-8);
					top: vw(1);
					background-image: url("~@/assets/icons/magnifier-green.svg");
					background-repeat: no-repeat;
					background-position: left bottom;
					background-size: vw(16) auto;
				}
			}

			&.list-move {
				transition: transform 0.2s linear 0.3s;
			}
			&.list-leave-active {
				margin-top: 3px;
				position: absolute;
				transition: opacity 0.3s linear;
				opacity: 0;
			}
			@at-root #{$c}__activefilters.list-leave-all #{$c}__toggle {
				position: relative !important;
			}
			@at-root #{$c}__activefilters.list-leave-notransition #{$c}__toggle {
				transition: none !important;
			}
		}

		#{$c}__activefilters {
			margin-top: vw(32);
			max-width: 70vw;

			#{$c}__toggle {
				background-color: $color-filter-toggle-selected-bg;
				color: $color-filter-toggle-selected;
			}
		}
	}

	.simple-keyboard {
		position: absolute;
		left: 0;
		right: 0;
		top: 9vw;
		width: 85vw;
		margin: auto;
		padding: vw(10);
		border-radius: vw(5);

		font-family: "Siemens Sans", arial;
		font-weight: 600;
		color: #fff;
		background: #000028dd;
		// background: transparent;

		opacity: 0;
		z-index: -1;
		transform: translateY(vw(10));
		transition: opacity 0.2s linear, transform 0.2s linear, max-height 0s linear 0.2s, padding 0s linear 0.2s;
		max-height: 0;
		padding: 0;

		&--show {
			z-index: 1000;
			opacity: 1;
			transform: translateY(0);
			padding: vw(10);
			transition: opacity 0.2s linear, transform 0.2s linear, max-height 0s, padding 0s;
			max-height: 30vw;
		}

		::v-deep() {

			.hg-row {
				justify-content: center;
				&:not(:last-child) {
					margin-bottom: vw(10);
				}
			}

			.hg-button {
				height: vw(50);
				width: vw(80);
				flex-grow: 0;
				font-size: vw(20);
				font-weight: 600;
				background: #7D8099;
				border: 0;
				border-radius: 0;

				&:not(:last-child) {
					margin-right: vw(10);
				}

				&.hg-button-bksp {
					background: #333353 url("~@/assets/icons/arrow-left.svg") center center no-repeat;
					background-size: vw(16) auto;
				}
				&.hg-button-close {
					width: vw(128);
					background: #333353;
				}
				&.hg-button-space {
					width: vw(560);
					> span {
						margin-top: vw(16);
					}
				}
				&.hg-button-enter {
					width: vw(128);
					background: #00CCCC;
					color: black;
					&:active {
						background: #00FFB9;
					}
				}

				&:active {
					background-color: #9999a9;
				}
			}
		}
	}
}
</style>
