<template>
	<div class="c-grid" :class="{ 'c-grid--idle': idle, '--debug': debug }">
		<canvas class="c-grid__canvas" width="1296" height="1920"></canvas>
		<div class="c-grid__container" :class="{ 'c-grid__container--rotated': showFilter }">
			<ul class="c-grid__list">
					<li
						class="c-grid__tile"
						v-for="(app, index) in apps"
						:key="app.app_id"
						:data-id="app.app_id"
						@click="listItemClick(app.app_id, $event)"
						:set="data = app.localized_data[$i18n.locale]"
						v-show="app.localized_data[$i18n.locale]"
					>
						<img v-if="data && data.app_image && !debug" :src="`${assetsUrl}${data.app_image.file}`" />
						<p v-if="data && !debug">{{ data.title }}</p>
						<p class="--debug" v-if="debug">
							{{ index + 1 }}<br>
							Col {{ (index % tilesPerRow) + 1 }}<br>
							Row {{ Math.floor((index) / tilesPerRow) + 1 }}
						</p>
					</li>
			</ul>
		</div>
		<ul class="c-grid__copies">
				<li
						class="c-grid__tile c-grid__tile--copy"
						v-for="app in appCopies"
						:key="app.app_id"
						:data-id="app.app_id"
						:set="data = app.localized_data[$i18n.locale]"
						v-show="app.localized_data[$i18n.locale]"
					>
						<img v-if="data && data.app_image && !debug" :src="`${assetsUrl}${data.app_image.file}`" />
						<p v-if="data">{{ data.title }}</p>
					</li>
		</ul>
		<div class="c-grid__tilewidth"></div>
		<div v-if="debug" class="debugText">
			{{debugText}}
		</div>
	</div>
</template>

<script>
import store from "../store";
import interact from 'interactjs'
import { getAssetsUrl } from "../utils/Urls";

const ANIMATION_DURATIONS = new Map([
	// Zeitraum, über den die jeweiligen Animationen gestaffelt gestartet werden in ms
	['alltiles_fadeout', 500],
	['alltiles_move', 500],
	['alltiles_fadein', 500],
	// Pausenzeiten zwischen den jeweiligen Animationsstufen in ms
	['delay_move', 500],
	['delay_fadein', 500],
]);

export default {
	name: 'AppList',
	props: [
		'showFilter',
		'uiLocked',
		'idle'
	],
	data() {
		return {
			debug: false,
			appCopies: [],
			allowClick: true,
			zoneColors: new Map([
				['25-0-41', 0],
				['48-0-43', 1],
				['96-0-46', 2],
				['143-0-49', 3],
				['191-0-52', 4],
				['239-0-55', 5]
			]),
			zones: [
				0,0,1,1,2,1,
				1,1,1,2,3,2,
				1,2,2,4,4,3,
				2,5,5,5,4,3,
				2,5,5,5,5,4,
				4,5,5,5,5,4,
				3,5,5,5,5,4,
				2,3,5,5,4,3,
				2,2,3,4,3,2,
			],
			animationResolves: [],
			mouseDown: false,
			resizeTimeout: null,
			tilesPerRow: 0,	// FIXME: debug
			debugText: "debug",	// FIXME: debug
		}
	},
	computed: {
		apps() {
			return store.state.apps;
		},
		filteredApps() {
			return store.getters.filteredApps;
		},
		filteredAppsIds() {
			return store.getters.filteredAppsIds;
		},
		assetsUrl() {
			return getAssetsUrl();
		}
	},
	emits: [
		'uiLock',
	],
	watch: {
		async filteredAppsIds(newVal, oldVal) {

			if (this.gridBlocked) {
				return false;
			}

			const wasIdle = this.idle;

			const cancelAnimation = async () => {

				tilesToFadein.forEach((tile, index) => {
					tile.classList.remove('c-grid__tile--hidden');
					tile.classList.remove('c-grid__tile--toshow');
				});

				document.querySelectorAll('.c-grid__tile--copy').forEach((copy) => {
					copy.classList.remove('c-grid__tile--activecopy');
					copy.style.opacity = null;
				});

				this.grid.classList.add('c-grid__list--transitions');
				this.grid.classList.remove('c-grid__list--hidden');
				tilesToFadein.forEach((tile) => tile.style.opacity = "");
				await this.animate(this.grid, 'transform', `translate(0, 0) scale(1.0)`);
				this.grid.classList.remove('c-grid__list--transitions');

				this.calculateZones();
				this.grid.classList.remove('c-grid__list--nozones');
				this.gridBlocked = false;
				this.$emit('uiLock', false);
			};

			//if (JSON.stringify(newVal) == JSON.stringify(oldVal)) {
			// return;
			//}

			document.querySelectorAll('.c-grid__tile--copy').forEach((copy) => {
				copy.classList.remove('c-grid__tile--activecopy');
				copy.style.opacity = null;
				copy.style.transform = 'translate(0, 0) scale(1.0)';
			});

			this.$emit('uiLock', true);
			this.gridBlocked = true;
			this.grid.classList.add('c-grid__list--transitions');
			this.grid.classList.add('c-grid__list--nozones');

			const scaleWidth = Math.max(this.grid.clientWidth, this.calculateGridWidth(this.filteredAppsIds.length));

			const scale = (window.innerWidth / scaleWidth) - 0.02;
			await this.animate(this.grid, 'transform', `translate(0, 0) scale(${scale})`);

			const copiesToMove = [];
			const copiesToHide = [];

			const tilesToHide = [];
			const tilesToNotHide = [];
			const tilesToFadein = [];

			// if (!this.idle) {debugger;}

			await new Promise((r) => {
				setTimeout(() => {
					this.grid.querySelectorAll('.c-grid__tile').forEach((li) => {
						const inFiltered = this.filteredAppsIds.includes(parseInt(li.dataset.id, 10));
						const isHidden = li.classList.contains('c-grid__tile--hidden');
						if (!isHidden) {
							const copy = this.tileCopies.get(li.dataset.id);
							if (copy) {
								const rect = li.getBoundingClientRect();
								copy.style.transform = `scale(${scale})`;
								copy.style.left = `${rect.x}px`;
								copy.style.top = `${rect.y}px`;
								copy.classList.add('c-grid__tile--activecopy');
							}
							if (!inFiltered) {
								copiesToHide.push(copy);
								// li.classList.add('c-grid__tile--hidden');
								tilesToHide.push(li);
							} else {
								copiesToMove.push(copy);
								tilesToNotHide.push(li);
							}
						} else if (inFiltered) {
							tilesToFadein.push(li);
						}
					});
					r();
				}, 100);
			});

			// if (!this.idle) {debugger;}

			while (tilesToHide.length < tilesToNotHide.length / 2) {
				const rndTile = tilesToNotHide[Math.floor(Math.random() * tilesToNotHide.length)];
				if (!tilesToHide.includes(rndTile)) {
					tilesToHide.push(rndTile);
					tilesToFadein.push(rndTile);
				}
			}
			const idsToHide = tilesToHide.map(tile => tile.dataset.id);
			// copiesToMove.filter(copy => !idsToHide.includes(copy.dataset.id));
			copiesToMove.forEach(copy => {
				if (idsToHide.includes(copy.dataset.id)) copiesToHide.push(copy)
			});


			store.commit('applyFilterSort');
			const tilesById = new Map();

			//
			// main animation
			//
			this.$nextTick(async () => {

				let cancelled = false;

				this.grid.querySelectorAll('.c-grid__tile').forEach((li) => {
					tilesById.set(li.dataset.id, li);
				});

				this.grid.classList.remove('c-grid__list--transitions');
				this.grid.classList.add('c-grid__list--hidden');
				this.calculateGrid();

				tilesToHide.forEach((tile) => {
					tile.classList.add('c-grid__tile--hidden');
				});
				tilesToFadein.forEach((tile) => {
					tile.classList.add('c-grid__tile--toshow');
				});

				const animationPromises = [];
				this.animationResolves = [];

				const hideSpeed = (this.idle ? 7 :  1 ) * ANIMATION_DURATIONS.get('alltiles_fadeout') || 500;
				const moveSpeed = (this.idle ? 7 :  1 ) * ANIMATION_DURATIONS.get('alltiles_move') || 500;
				const fadeinSpeed = (this.idle ? 7 :  1 ) * ANIMATION_DURATIONS.get('alltiles_fadein') || 500;
				const delayMove = (this.idle ? 3 :  1 ) * ANIMATION_DURATIONS.get('delay_move') || 500
				const delayFadein = (this.idle ? 3 :  1 ) * ANIMATION_DURATIONS.get('delay_fadein') || 500;

				copiesToHide.forEach((copy, index) => {
					animationPromises.push(
						new Promise((r) => {
							this.animationResolves.push(r);
							setTimeout(async() => {
								if (wasIdle && !this.idle) { r(); return; }
								await this.animate(copy, 'opacity', '0');
								r();
							}, hideSpeed / copiesToHide.length * index);
						})
					);
				});

				if (wasIdle && !this.idle) {
					cancelAnimation();
					return;
				}

				if (copiesToHide.length > 0) {
					await new Promise(r => setTimeout(r, delayMove));
				}

				if (wasIdle && !this.idle) {
					cancelAnimation();
					return;
				}

				let tilesWereMoved = false;
				copiesToMove.forEach((copy, index) => {
					animationPromises.push(
						new Promise((r) => {
							this.animationResolves.push(r);
							const rect = tilesById.get(copy.dataset.id).getBoundingClientRect();
							const rectCopy = copy.getBoundingClientRect();
							if (Math.round(rect.x) == Math.round(rectCopy.x) && Math.round(rect.y) == Math.round(rectCopy.y)) {
								// nothing to move
								r();
								return;
							}
							if (wasIdle && !this.idle) { r(); return; }
							tilesWereMoved = true;
							setTimeout(async() => {
								if (wasIdle && !this.idle) { r(); return; }
								const p1 = this.animate(copy, 'left', `${rect.x}px`);
								const p2 = this.animate(copy, 'top', `${rect.y}px`);
								await Promise.all([p1, p2]);
								r();
							}, moveSpeed / copiesToMove.length * index);
						})
					);
				});

				if (wasIdle && !this.idle) {
					cancelAnimation();
					return;
				}

				if (tilesWereMoved && (wasIdle && !this.idle)) {
					await new Promise(r => setTimeout(r, delayFadein));
				}

				if (wasIdle && !this.idle) {
					cancelAnimation();
					return;
				}

				tilesToFadein.forEach((tile, index) => {
					animationPromises.push(
						new Promise((r) => {
							this.animationResolves.push(r);
							setTimeout(async() => {
								if (wasIdle && !this.idle) { r(); return; }
								tile.classList.remove('c-grid__tile--hidden');
								await this.animate(tile, 'opacity', 1);
								tile.classList.remove('c-grid__tile--toshow');
								r();
							}, fadeinSpeed / tilesToFadein.length * index);
						})
					);
				});


				await Promise.all(animationPromises);

				cancelAnimation();

				/*document.querySelectorAll('.c-grid__tile--activecopy').forEach((copy) => {
					copy.classList.remove('c-grid__tile--activecopy');
					copy.style.opacity = "";
				});

				this.grid.classList.add('c-grid__list--transitions');
				this.grid.classList.remove('c-grid__list--hidden');
				tilesToFadein.forEach((tile) => tile.style.opacity = "");
				await this.animate(this.grid, 'transform', `translate(0, 0) scale(1.0)`);
				this.grid.classList.remove('c-grid__list--transitions');

				this.calculateZones();
				this.grid.classList.remove('c-grid__list--nozones');
				this.gridBlocked = false;
				this.$emit('uiLock', false);*/
			});
		},
		idle() {
			if (!this.idle) {
				this.animationResolves.forEach((r) => r() );
			}
		},
		showFilter() {
			this.gridBlocked = this.showFilter;
		},
		uiLocked(state) {
			this.gridBlocked = state;
		},
	},
	methods: {
		animate(elem, param1, param2) {
			return new Promise((r) => {
				const f = (e) => {
					if (e.target === elem) {
						r(elem);
						e.stopPropagation();
						elem.removeEventListener('transitionend', f);
					}
				};
				elem.addEventListener('transitionend', f);
				if (param2 == undefined) {
					elem.classList.toggle(param1);
				} else {
					elem.style[param1] = param2;
				}
				// timeout just to make sure we won't hang
				setTimeout(r, 5000);
			});
		},
		getTiles() {
			return this.tiles = this.grid.querySelectorAll('li:not(.c-grid__tile--hidden)');
		},
		getTileWidth() {
			// offsetWidth rounds to nearest integer, getBoundingRect returns fractional values
			// but getBoundingRect is calculated after css transforms
			// => use hidden untransformed tile for width calculation
			// const tile = this.getTiles()[0];
			const tile = this.tileWidth;
			const tileStyle = window.getComputedStyle(tile);

			const rect = tile.getBoundingClientRect();
			return rect.width + parseFloat(tileStyle.marginLeft) + parseFloat(tileStyle.marginRight);
			// return tile.clientWidth + parseFloat(tileStyle.marginLeft) + parseFloat(tileStyle.marginRight);
		},
		listItemClick(appId, e) {
			const li = e.currentTarget;
			if (this.allowClick && !this.gridBlocked && !this.idle) {
				this.$router.push({ name: 'AppDetails', params: { id: appId } });

				if (li) {
					li.classList.add('c-grid__tile--clicked');
					setTimeout(() => {
						li.classList.remove('c-grid__tile--clicked');
					}, 100);
				}
			}
			return false;
		},
		calculateGridWidth(tileCount) {
			if (!tileCount) {
				tileCount = this.filteredAppsIds.length;
			}
			const tileWidth = this.getTileWidth();
			const width = Math.ceil(Math.pow(tileCount, 0.43)) * tileWidth

			return width;
		},
		async calculateGrid() {
			const count = this.filteredApps.length;
			const tileWidth = this.getTileWidth();
			const width = Math.ceil(Math.pow(count, 0.43)) * tileWidth
			this.grid.style.width = `${width}px`;
			// set min-width to counter display: flex
			this.grid.style.minWidth = `${width}px`;

			// reset interactjs position

			// const transform = this.grid.style.transform;
			// const resetTransform = 'translate(0px, 0px)';
			// if (transform && transform != resetTransform) {
			// 	this.grid.classList.add('c-grid__list--transitions');
			// 	await this.animate(this.grid, 'transform', resetTransform);
			// 	this.grid.classList.remove('c-grid__list--transitions');
			// }
			// this.grid.style.transform = `translate(0px, 0px)`
			this.grid.setAttribute('data-x', `0px`);
			this.grid.setAttribute('data-y', `0px`);

			// if (width >= window.innerWidth) {
			if (count == this.apps.length) {
				this.gridContainer.classList.add('c-grid__container--borderbound')
			} else {
				this.gridContainer.classList.remove('c-grid__container--borderbound')
			}
		},
		calculateZones(force = false) {

			// FIXME: debouncing for performance?
			const now = Date.now();
			if (now - this.lastCalculateZones < 50 && !force) {
				return false;
			}
			this.lastCalculateZones = now;

			const canvas = document.querySelector('.c-grid__canvas');
			const ctx = canvas.getContext('2d');

			// scaling ratio for tile positions (canvas has a fixed resolution)
			const ratio = canvas.width / 1.2 / window.innerWidth;

			// find visible tiles to limit loop

			// FIXME: can't use boundingClientRect during css perspective transform
			// const gridRect = this.grid.getBoundingClientRect();
			const gridRect = {
				x: this.gridContainer.parentNode.offsetLeft + this.gridContainer.offsetLeft + this.grid.offsetLeft + parseFloat(this.grid.dataset.x),
				y: this.gridContainer.parentNode.offsetTop + this.gridContainer.offsetTop + this.grid.offsetTop + parseFloat(this.grid.dataset.y),
				width: this.grid.clientWidth - 1,
				height: this.grid.clientHeight - 1,
			};

			const tileWidth = this.getTileWidth();
			const tilesPerRow = Math.ceil(gridRect.width / tileWidth);
			const minRow = Math.max(Math.ceil(gridRect.y * -1 / tileWidth), 0);
			const maxRow = minRow + Math.ceil(window.innerHeight / tileWidth);
			const minColumn = Math.max(Math.floor(gridRect.x * -1 / tileWidth), 0);
			const maxColumn = minColumn + Math.ceil(window.innerWidth / tileWidth);

			// FIXME: debug
			if (this.debug) {
				this.tilesPerRow = tilesPerRow;
				this.debugText = `tw: ${tileWidth}, tpr:${tilesPerRow},r:${minRow}-${maxRow},c:${minColumn}-${maxColumn}`;
				// this.debugText = `${gridRect.y} / ${tileWidth} / ${minRow}`;
			}

			const tiles = [];
			this.getTiles().forEach((tile, i) => {
					if (this.debug) tile.classList.remove('--debug');
					const column = i % tilesPerRow;
					const row = Math.floor(i / tilesPerRow);
					if (column >= minColumn && column <= maxColumn
						&& row >= minRow && row <= maxRow) {

						tiles.push(tile);
						if (this.debug) tile.classList.add('--debug');
					}
			});

			// loop visible tiles, set class depending on canvas color at position
			tiles.forEach((tile) => {
				const rect = tile.getBoundingClientRect();
				const middleX = Math.floor(rect.x * ratio + rect.width / 2);
				const middleY = Math.floor(rect.y *ratio + rect.height / 2);
				const p = ctx.getImageData(middleX, middleY, 1, 1).data;
				const zoneKey = `${p[0]}-${p[1]}-${p[2]}`;
				const className = `in-zone-${this.zoneColors.get(zoneKey)}`;

				if (!tile.classList.contains(className)) {
					tile.classList.remove('in-zone-0', 'in-zone-1', 'in-zone-2', 'in-zone-3', 'in-zone-4', 'in-zone-5')
					if (this.zoneColors.get(zoneKey) != undefined) {
						tile.classList.add(className);
					}
				}

			});
		}
	},
	mounted() {
		const c = this;


		this.gridContainer = document.querySelector('.c-grid__container');
		this.grid = document.querySelector('.c-grid__list');
		this.tileWidth = document.querySelector('.c-grid__tilewidth');
		this.copyContainer = document.querySelector('.c-grid__copies');

		this.appCopies = [...this.apps];
		this.$nextTick(() => {
			this.tileCopies = new Map();
			const copies = this.copyContainer.querySelectorAll('.c-grid__copies .c-grid__tile--copy');
			copies.forEach((copy) => {
				this.tileCopies.set(copy.dataset.id, copy);
			});
		})

		// DEBUG: initial sorting
		if (this.filteredAppsIds.length > 0)  {
			this.getTiles().forEach((tile) => {
				if (!this.filteredAppsIds.includes(parseInt(tile.dataset.id, 10))) {
					tile.classList.add('c-grid__tile--hidden');
				}
			});
			this.calculateGrid();
			this.calculateZones();
		}

		const canvas = document.querySelector('.c-grid__canvas');
		const ctx = canvas.getContext('2d');

		// draw zones to canvas
		const zoneWidth = canvas.width / 6;
		const invertedZoneColors = new Map(Array.from(this.zoneColors, a => a.reverse()));
		this.zones.forEach((zone, index) => {
			const column = index % 6;
			const row = Math.floor(index / 6);
			const rgb = invertedZoneColors.get(zone).split('-');
			ctx.fillStyle = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
			ctx.fillRect(column * zoneWidth, row * zoneWidth, zoneWidth, zoneWidth);
		});

		c.calculateGrid();
		c.calculateZones();

		interact('.c-grid__list')
			.draggable({
				inertia: {
					resistance: 7,
					minSpeed: 30,
					endSpeed: 10
				},
				modifiers: [
					interact.modifiers.restrictRect({
						restriction: 'parent',
						endOnly: true
					})
				],
				listeners: {
					// call this function on every dragmove event
					move(e) {
						if (c.gridBlocked) {
							return;
						}
						// c.allowClick = false;
						var target = e.target
						// keep the dragged position in the data-x/data-y attributes
						var x = (parseFloat(target.getAttribute('data-x')) || 0) + e.dx
						var y = (parseFloat(target.getAttribute('data-y')) || 0) + e.dy

						// translate the element
						target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

						// update the position attributes
						target.setAttribute('data-x', x)
						target.setAttribute('data-y', y)

						c.calculateZones();
					},
					end() {
						c.calculateZones(true);
					}
				}
			}).on('down', () => {
				c.mouseDown = true;
			}).on('move', () => {
				if (c.mouseDown) {
					c.allowClick = false;
				}
			}).on('up', () => {
				c.mouseDown = false;
				setTimeout(() => {
					c.allowClick = true;
				}, 10);
			});

		window.onresize = () => {
			if (c.resizeTimeout) { clearTimeout(c.resizeTimeout); }
			c.resizeTimeout = setTimeout(() => {
				c.calculateGrid();
				c.calculateZones();
			}, 200);
		}
	},
}

</script>

<style lang="scss" scoped>
.c-grid {

	$grid_animation_zoom_duration: 0.5s;
	$grid_animation_tile_fadein_duration: 0.5s;
	$grid_animation_tile_fadeout_duration: 0.5s;
	$grid_animation_tile_move_duration: 0.5s;

	$grid_animation_zoom_duration_idle: 1s;
	$grid_animation_tile_fadein_duration_idle: 1s;
	$grid_animation_tile_fadeout_duration_idle: 1s;
	$grid_animation_tile_move_duration_idle: 1s;

	$c: &;

	&__container {
		// background-color: green;
		width: 50%;
		height: 50%;
		position: absolute;
		top:35vw;
		bottom:0;
		left:0;
		right:0;
		margin: auto;
		transition: transform 0.5s ease-in-out;

		display: flex;
		align-items: center;
    // flex-direction: column;
    justify-content: center;

		&--rotated {
			transform: perspective(100vw) rotateY(-47deg);
		}

		&--borderbound {
			top: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__tilewidth {
		position: absolute;
		z-index: -1;
		opacity: 0;
		width: vw(220);
		aspect-ratio: 1;
		margin: vw(15);
	}

	&__list {
		width: 200%;
		display: inline-block;
		touch-action: none;
		box-sizing: border-box;
		user-select: none;
		transform: translate(0px, 0px);
		padding: 0;
		display: flex;
		flex-wrap: wrap;

		&--transitions {
			transition: transform $grid_animation_zoom_duration ease-in-out;
			#{$c}__tile {
				transition: none;
			}
		}
		&--nozones {
			#{$c}__tile {
				p, img {
					opacity: 1 !important;
				}
			}
		}
		&--hidden {
			#{$c}__tile {
				opacity: 0;
				transition: none;
			}
		}
	}

	&__copies {
		position: fixed;
		top: 0;
		left: 0;
		height: 0;
		width: 0;
	}

	&__tile {
		// width: 18vw;
		width: vw(220);
		aspect-ratio: 1;
		margin: vw(15);
		padding: vw(25);
		overflow: hidden;
		background: $color-tiles;
		&--clicked {
			background: #333353;
		}
		&.--debug {
			background: #666600 !important;
		}
		border-radius: 10%;

		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		font-size: vw(14);
		line-height: 2em;
		font-weight: 900;

		transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;

		p, img {
			transition: opacity 0.3s ease-in-out;
		}
		img {
			opacity: 0.2;
			width: vw(80);
			aspect-ratio: 1;
			object-fit: contain;
			margin-bottom: vw(15);
		}
		p {
			flex: 1;
			display: flex;
			align-items: center;
			opacity: 0;
			margin-bottom: 0;
		}

		p.--debug, em {
			font-size: 30px;
		}

		&.in-zone {
			&-0 {
				// background: yellow;
				img { opacity: 0.2;}
				p { opacity: 0;}
			}
			&-1 {
				// background: green;
				img { opacity: 0.2;}
				p { opacity: 0;}
			}
			&-2 {
				img { opacity: 0.5;}
				p { opacity: 0.3;}
			}
			&-3 {
				img { opacity: 0.6;}
				p { opacity: 0.4;}
			}
			&-4 {
				img { opacity: 0.8;}
				p { opacity: 0.6;}
			}
			&-5 {
				img, p { opacity: 1;}
			}
		}

		&--hidden {
			opacity: 0;
			display: none;
		}
		&--toshow {
			transition: opacity $grid_animation_tile_fadein_duration linear !important;
			display: flex;
		}

		&--copy {
			position: fixed;
			display: none;
			transform-origin: 0 0;
			margin: 0;
			img, p { opacity: 1;}
			// background: rgba(200, 0, 0, 0.5);
			// opacity: 0.1;
		}
		&--activecopy {
			display: flex;
			transition: left $grid_animation_tile_move_duration linear, top $grid_animation_tile_move_duration linear, opacity $grid_animation_tile_fadeout_duration linear;
		}
	}

	&__canvas {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 0;
		width: 120%;
		height: 100%;
		opacity: 0;
	}
	&.--debug #{$c}__canvas {
		opacity: 1;
	}


	&--idle {
		#{$c}__tile--toshow {
			transition: opacity $grid_animation_tile_fadein_duration_idle linear !important;
		}
		#{$c}__tile--activecopy {
			transition: left $grid_animation_tile_move_duration_idle linear, top $grid_animation_tile_move_duration_idle linear, opacity $grid_animation_tile_fadeout_duration_idle linear;
		}
		#{$c}__list--transitions {
			transition: transform $grid_animation_zoom_duration_idle ease-in-out;
		}
	}
}

// FIXME: debug
.debugText {
	position: absolute;
	top:0vw;
	right:0;
	font-size: 4vw;
	background: red;
	color: #fff;
}
</style>
