import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import Vue3Marquee from 'vue3-marquee'

(async () => {
	await store.dispatch('populate');
	createApp(App)
		.use(i18n)
		.use(store)
		.use(router)
		.use(Vue3Marquee)
		.mount('#app')
})();