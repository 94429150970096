<template>
<ul class="c-localeswitcher">
	<li v-for="locale in locales" :key="locale" @click="switchLocale(locale)">
		{{locale}}
	</li>
</ul>
</template>

<script>
export default {
	name: 'LocaleSwitcher',
	methods: {
		switchLocale(locale) {
			if (this.$i18n.locale !== locale) {
				this.$i18n.locale = locale;
			}
		}
	},
	data() {
		return {
			locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
		}
	}
}
</script>

<style lang="scss" scoped>
.c-localeswitcher {
	position: absolute;
	z-index: 11;
	top: 0;
	right: 0;
	display: flex;
	padding: 0;
	margin: 0;

	li {
		display: flex;
		height: 2vw;
		width: 2vw;
		align-items: center;
		justify-content: center;
		background-color: #ffffff;
		margin: 0.2vw;
		color: $color-background;
		cursor: pointer;
	}
}
</style>