<template>
	<div id="app-container">
		<img src="@/assets/logo.svg" class="c-logo" @click="logoutCheck">
		<!-- <LocaleSwitcher /> -->
		<router-view v-slot="{ Component }">
			<transition name="fade">
				<keep-alive>
					<component :is="Component" :idle="idle" />
				</keep-alive>
			</transition>
		</router-view>
		<!-- <Home />
		<AppDetails /> -->
	</div>
</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import store from "./store";
import { isElectron } from './utils/Urls';
if (!isElectron()) {
	window.config = new Map([
		['idleTimeout', 60000],       // time until idle mode starts without input
		['idleRepeat', 15000],    // time for animation to repeat during idle mode
		['bannerMessage', 'Start with Industrial Edge at no cost.<br>3-month trials for many Apps now available!'],
		['tickerHeader', "@Industrial IoT Ecosystem Dialogstation"],      // header for news ticker
		['tickerMessages', [         // messages for news ticker
				// "Want to learn more just turn around and see you at the Industrial IoT Ecosystem Dialog Station",
				// "Monday, 30th of May 9:00 am – 12:00 pm seioTec",
				// "Monday, 30th of May 1:00 am – 4:00 pm MVTec",
				// "Tuesday, 31st of May 9:00 am – 12:00 pm Tosibox",
				// "Tuesday, 31st of May 1:00 am – 4:00 pm Weidmüller",
				// "Wednesday, 1st of June 1:00 pm – 4:00 pm Focus Automation",
				// "Thursday, 2nd of June 9:00 am – 12:00 pm Softing"
		]],
		['tickerSpeed', 5000],        // time each messages stays on screen in ms
		['welcomeHeadline', 'INDUSTRIAL EDGE APP GALLERY'],
		['welcomeText', 'Transform your shopfloor data into meaningful information<br>Browse our catalogue of Siemens and third-party apps!'],
		['welcomeTextFontSize', 36],
	]);
}

export default {
	name: 'App',
	data() {
		return {
			idleTimeout: null,
			idleInterval: null,
			idle: false,
			logoutTimeout: 0,
		}
	},
	components: {
		LocaleSwitcher,
	},
	methods: {
		setIdle() {
			this.idle = true;
			this.$router.push({ name: 'Home' });

			let filterCount = 0;
			store.getters.filters.forEach((value, filter) => {
				if (value) {
					filterCount += value.length;
				}
			});

			setTimeout(() => {
				if (filterCount > 0) {
					store.commit('clearFilters');
					store.commit('applyFilters');
				} else {
					store.commit('shuffle');
				}
			}, 500);

			this.idleInterval = setInterval(() => {
				store.commit('shuffle');
			}, window.config.get('idleRepeat') || 20000);
		},
		setIdleTimeout() {

			setTimeout(() => {

				this.idle = false;
				clearInterval(this.idleInterval);
				clearTimeout(this.idleTimeout);

				this.idleTimeout = setTimeout(this.setIdle, window.config.get('idleTimeout') || 10000);
			}, 100)
		},
		logoutCheck() {
			this.logoutTimeout += 1;
			setTimeout(() => {
				this.logoutTimeout = 0;
			}, 1000);
			if (this.logoutTimeout > 2) {
				fetch('res://exit-app');
			}
			setTimeout(this.setIdle, 100);
		}
	},
	mounted() {
		// if (process.env.NODE_ENV !== 'development') {
			this.setIdleTimeout();
			document.body.addEventListener('mousedown', () => {
				this.setIdleTimeout();
			});
			document.body.addEventListener('touchstart', () => {
				this.setIdleTimeout();
			});
		// }
	}
}

</script>

<style lang="scss">
#app {
	height: 100%;
	// overflow: hidden;
}
#app-container {
	// height: 100%;
	aspect-ratio: 9 / 16;
	width: 100%;
	background-color: #000028;
	margin: auto;
	position: relative;

	overflow: auto;
}

.c-logo {
	width: 20vw;
	position: absolute;
	left: vw(53);
	top: vw(97.5);
	z-index: 11;
}

.c-page {
	position: absolute;
	top:0;
	left:0;
	width: 100%;
	// height: 100%;
	padding-top: vw($length-page-paddingtop);
	padding-left: vw(53);
	padding-right: vw(53);
	transition: opacity 0.3s;
	opacity:1;
}

.fade-enter-active, .fade-leave-active {
	opacity: 0;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

</style>
