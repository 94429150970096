<template>
	<div class="c-enter">
			<button
				class="o-button--filter"
				@click="enter"
				>
					<span>
						Find out <strong>more</strong>
					</span>
					<svg xmlns="http://www.w3.org/2000/svg" width="33.567" height="58.083" viewBox="-0.017 -0.017 33.567 58.083"><path d="M33.522 27.808V44.16c0 8.176-5.723 13.9-13.9 13.9A13.39 13.39 0 0 1 6.43 47.635c-1.816-7.282-4.06-16.044-4.823-18.04a5.543 5.543 0 0 1 7.26 3.547 83.992 83.992 0 0 1 1.766 6.77L10.63 13.5a2.862 2.862 0 0 1 5.723 0v.408l.003 16.353h.815V23.72a2.453 2.453 0 1 1 4.906 0v6.54h.817v-4.905a2.453 2.453 0 0 1 4.906 0v4.906h.817v-2.453a2.453 2.453 0 1 1 4.906 0ZM4.738 18.749a10.219 10.219 0 1 1 18.558-2.39l2.96 1.479a13.488 13.488 0 1 0-24.059 3.029l2.541-2.118Z" fill="#000028" fill-rule="evenodd" data-name="Pfad 41"/></svg>
			</button>
	</div>
</template>

<script>
export default {
	name: 'EnterButton',
	methods: {
		enter() {
			this.$emit('entered', true);
		}
	},
	emits: ['entered']
}
</script>

<style lang="scss">
.c-enter {

	$c: &;

	position: absolute;
	z-index: 10;
	opacity: 1;

	.o-button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: vw(130);
		width: vw(360);
		font-size: vw(32);
		font-weight: 400;
		padding-left: vw(42);
		padding-right: vw(42);

		@keyframes pulse {
			0% { opacity: 1; }
			50% { opacity: 0.8; }
			100% { opacity: 1; }
		}

		animation: 2s ease pulse infinite;


		svg {
			height: 80%;
		}
	}
}
</style>